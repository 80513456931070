<template>
<div>
    <div class="row">
        <div class="col-md-12">
            <button class="bt" style="float:right;float: right;background-color: #389532;color: white;border:3px solid #9fd67d;" v-if="!checkformore" type="button" @click="addmore()"><i class="fa fa-phone"></i>&nbsp;<i class="fa fa-plus"></i></button>
             <table>
                <tr v-for="(item) in mobiles" v-bind:key="item.id">
                    <td>
                        {{ item.mobile }}
                    </td>
                    <td>
                        <span style="float:right;margin-left:35px;color:red;" type="button" @click="deleteoperation(item)" ><i class="fa fa-times"></i></span>
                    </td>
                    <td>
                        <span style="float:right;margin-left:15px;color:#f2a009;" title="Not Verified !" alt="Not Verified !" type="button" ><i class="fa fa-exclamation-circle"></i></span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="col-md-12" :class="{ 'form-group--error': $v.newmobile.mobile.$error }">
            <div v-if="checkformore">
                <div style="margin-bottom: 9px;">
                    <input  class="form-control form-cascade-control input-small" type="text" v-model="$v.newmobile.mobile.$model" @input="mobilenoValid()" max="10" required>
                    <div class="text-end ">{{ this.newmobile.mobile.length }}/10</div>
                    <span class="error" v-if="!$v.newmobile.mobile.required">Field is required</span>
                    <span class="error" v-if="!$v.newmobile.mobile.maxLength">Mobile No. should not exceed {{$v.newmobile.mobile.$params.maxLength.max}} letters.</span>
                    <span class="error" v-if="!$v.newmobile.mobile.minLength">Mobile No. should of minimum 10 digit</span>
                    <span class="error" v-if="this.checkduplicate">Mobile No. already exist.</span>
                </div>
                <button class="btn btn-primary" style="width: 68px;height: 30px;margin-top: 2px;" type="button" @click="mobileduplicate()">Save</button>
                <button class="btn btn-primary" style="width: 68px;height: 30px;margin-top: 2px;" type="button" @click="cancel()">Cancel</button>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { required, maxLength,minLength } from 'vuelidate/lib/validators'
export default {
    data(){
        return {
            newmobile:{
                id:0,
                mobile:''
            },
            addnew:false,
            checkduplicate:false,
        }
    },
    mounted(){
        
    },
    validations:{
        newmobile:{
            mobile:{
                required,
                minLength:minLength(10),
                maxLength:maxLength(10)
            }
        }
    },
    computed:{
        checkformore(){
            if(this.mobiles==null || this.mobiles.length==0 ){
                return true;
            }
            if(this.addnew){
                return true;
            }
            return false;
        },
        ...mapGetters([
		      'mobiles'
		]),
        
    },
    methods:{
        addmore(){
            this.newmobile = {id:0,mobile:''}
            this.addnew = true;
        },
        save(){
            this.$store.commit('updateappendmobile',this.newmobile)
            this.newmobile = {id:0,mobile:''}
            this.addnew = false;
        },
        cancel(){
            this.newmobile = '';
            this.addnew = false;
        },
         deleteoperation(item){
            this.$store.commit("deletemobile",item)
        },
        mobileduplicate(){
            if(this.newmobile.mobile !=''){
                let param={mobileno : "+91"+this.newmobile.mobile } 
                this.$http.post('api/user/checkforcustomer',param) 
                .then((response) => this.processCheckResponse(response.data))
                .catch((err) => {
                    console.log('', err)
                });
            }
        },
        processCheckResponse(data){
            // console.log("MobileData"+data);
            if(data.user!=null){
                this.checkduplicate=true;
                if(data.user.iscustomer == 0 && data.user.isemployee==0){
                    this.$store.commit('assignloadingstatus',1);
                    let param={iscustomer : 1 , id: data.user.id}
                    this.$http.post('api/profile/createupdate',param) 
                    .then((response) => this.processCreateUserResponse(response.data))
                    .catch((err) => {
                        console.log(err);
                    });
                }
               
            }else{
                this.checkduplicate=false
                this.save();
            }
        },
        processCreateUserResponse(data){
            this.$store.commit('assignloadingstatus',0);
            let val=data;
            return val;
        },
        mobilenoValid(){
            this.newmobile.mobile = this.newmobile.mobile.replace(/\D/g, '')
            if(this.newmobile.mobile.length>10){
                this.newmobile.mobile = this.newmobile.mobile.slice(0,10)
            }
        }
        
    }
}
</script>
<style scoped>
.bt:hover{
    background-color: #1f6707 !important;
}
</style>